import React, {useState} from 'react';
// import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
// import recaptchaSubmit from '../util/recaptchaSubmit'
import jsonRequest from '../util/jsonRequest'
import { navigate } from "gatsby"
import { Button, Form, InlineNotification, TextInput} from 'carbon-components-react';

export default function SetNewPasswordForm() {
  const [username, setUsername] = useState("")
  // const [tempName, setTempName] = useState("")
  const [tempPass, setTempPass] = useState("")
  const [newPass, setNewPass] = useState("")
  // const [confirmNewPass, setConfirmNewPass] = useState("")
  const [message, setMessage] = useState(null)
  // const { executeRecaptcha } = useGoogleReCaptcha();
  const [successPass, setSuccess] = useState(false);

  const handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    switch(name) {
      case 'username':
        setUsername(value)
        break
      // case 'tempName':
      //   setTempName(value)
      //   break
      case 'tempPass':
        setTempPass(value)
        break
      case 'newPass':
        setNewPass(value)
        break
      // case 'confirmNewPass':
      //   setConfirmNewPass(value)
        // break
      default:
        console.log('field has no state')
    }
  }

  const handleSubmit = async event => {
    event.preventDefault();
    event.persist();

    try {
      // const valid = await recaptchaSubmit(executeRecaptcha)

      const payload = {
        name: username,
        temp_pass: tempPass,
        new_pass: newPass,

      };

      const response = await jsonRequest("user/lost-password-reset?_format=json", "POST", payload);

      if(response.ok){
        setSuccess(true)
        setTimeout(function () {
          navigate('/login')
      }, 790);
      }else{
        setMessage("Unable to reset password.");
      }
    } catch (err) {
      setMessage("Unable to reset password.");
    }
  }

  return (
    <>
    {!successPass ? 
    <>
      <div>
        <p>You should receive an email shortly with a temporary password</p>
        <p>Please use it here to reset your password</p>
      </div>
      <Form className="reset-form" onSubmit={handleSubmit}>
        {message}
        <TextInput
          light
          required
          placeholder="Username"
          type="text"
          name="username"
          value={username}
          onChange={handleInputChange}
        />
        <TextInput 
          light
          required
          placeholder="Temporary Password"
          type="password"
          name="tempPass"
          value={tempPass}
          onChange={handleInputChange}
        />
        <TextInput 
          light
          required
          placeholder="New Password"
          type="password"
          name="newPass"
          value={newPass}
          onChange={handleInputChange}
        />
        <Button type="submit">Change password</Button>
      </Form>
      </>
      :
      <>
      <div >
            <div className="padd">
                <InlineNotification kind="success" title=''>
                  <p>Reset successful! Automatically redirecting to login page</p> 
                </InlineNotification>
            </div>
        </div>
      </>}
    </>
  )
}