
import { Button, Form, TextInput} from 'carbon-components-react';
import React, {useState} from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import recaptchaSubmit from '../util/recaptchaSubmit'
import jsonRequest from '../util/jsonRequest'
import SetNewPasswordForm from '../components/SetNewPasswordForm'

export default function ResetPasswordPage({location}) {
  const [userEmail,setUserEmail] = useState("")
  const [ok,setOk] = useState(false)
  const [message, setMessage] = useState(null)
  const { executeRecaptcha } = useGoogleReCaptcha();
  // let pageContext = {frontmatter: {title: 'Reset Password'},
  // relativePagePath: "/password-reset.js",
  // titleType: "page"}
  // pageContext = {...pageContext, frontmatter: {title: "Reset Password"}};

  const handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    // const name = target.name;
    setUserEmail(value);
  }

  const handleSubmit = async event => {
    event.preventDefault();
    event.persist()

    try {      
      const valid = await recaptchaSubmit(executeRecaptcha)
      if (!valid) throw new Error("Failed recaptcha")
      const payload = {mail: userEmail};
      console.log(payload)
      const response = await jsonRequest("user/lost-password?_format=json", "POST", payload);

      if(response.ok){
        setOk(true);
      }else{
        setMessage(" Unable to send reset password email to "+userEmail);
      }
    } catch (err) {
      setMessage(err.message);
    }
  }
  return (     
    // <Default pageContext={pageContext} location={location}>
    // {
      !ok ? (
        <div  style={{maxWidth: `620px`}}>
          <div>
            <p>Enter email associated with the account you would like to password reset</p>
          </div>
          <Form className="reset-form" onSubmit={
            async (e) => {
              e.preventDefault();
              try {
                await handleSubmit(e);
              } catch (err) {
                setMessage(err.message);
              }
            }
          }>
            <TextInput
              light
              required
              type="text"
              labelText="Email"
              name="userEmail"
              id="userEmail"
              onChange={handleInputChange}
              warn={!!message}
              warnText={message}
            />
            <Button type="submit">Reset Password</Button>
          </Form>
        </div>
      ) : (<SetNewPasswordForm />)
    // }
    // </Default>
  )
}
