import { siteMetadata } from "../../gatsby-config";

export default async function jsonRequest(endpoint, method, payload){
  const url = `${siteMetadata.drupalUrl}/${endpoint}`;
  const init = {
    method: method,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  }
  if(payload){
    init.body = JSON.stringify(payload)
  }
  return fetch(url,init);
}
